.flexible-item {
	margin-bottom: 48px;
	display: block;

	&:last-child {
		margin-top: 60px;
	}
}

.image-link {
	display: block;
	margin-bottom: 24px;
	text-decoration: none;

	h2 {
		margin-bottom: 24px;
	}

	p {
		max-width: 80%;
		text-decoration: none;
	}

	&:last-child {
		margin-bottom: 0px;
	}

	&:hover h2,
	&:hover p {
		text-decoration: underline;
	}
}

.link-image img {
	width: 100%;
}

.link-image-arrow {
	width: 32px;
	height: auto;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
}

.flexible-textarea {
	.label {
		font-weight: 600;
		transform: translateY(-10px);
	}

	h2 {
		margin: 24px 0;
	}

	p {
		max-width: 80%;
	}

	a {
		font-weight: 600;
		text-decoration: underline;
	}
}

.flexible-contacts {
	padding: 0;
}

.flexible-contact {
	h3 {
		margin-bottom: 10px;
	}

	.blue & {
		padding-bottom: 24px;
		padding-top: 24px;

		h3 {
			color: $primaryBlue;
		}
	}

	.text-holder {
		display: inline-block;
		width: calc(100% - 90px);
		min-height: 90px;
	}

	.icon {
		position: absolute;
		right: 0;
		display: inline-block;

		img {
			height: 90px;
			width: auto;
			display: block;
		}
	}
}

.flexible-featured-image {
	max-width: 1218px;

	img {
		width: 100%;
		display: block;
	}
}

.icon-wrapper {
	position: absolute;
	right: 0;
	display: inline-block;

	img {
		height: 90px;
	}
}

.quickLinkWrapper {
	margin-bottom: 24px;
}

.quickLink {
	display: flex;
	align-items: center;
	background-color: $primaryBlue;
	color: #fff;
	padding: 24px 26px;
	font-weight: 600;
	font-size: 24px;

	&:after {
		content: '';
		width: 32px;
		height: 25px;
		@include white-arrow(32px);
		transition: .2s;
		margin-right: 10px;
		margin-left: auto;
	}

	&:hover {
		&:after {
			margin-right: 0;
		}
	}
}
