.top-image {
	position: relative;
	margin-bottom: 90px;

	@include below($m) {
		margin-bottom: 60px;
	}

	.image {
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;

			&.mobile {
				@include above($m) {
					display: none;
				}
			}

			&.desktop {
				@include below($m) {
					display: none;
				}
			}
		}
	}

	.image::after {
		content: '';
		position: absolute;
		inset: 0;
		background-color: black;
		opacity: 0.2;
	}

	.content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		>div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
			width: 100%;
		}

		p,
		h1 {
			text-transform: uppercase;
			text-align: center;
		}

		&.fontBlack {
			color: $black;
		}

		&.fontWhite {

			p,
			h1 {
				color: $white;
			}
		}

		.svg-wrapper img {
			max-height: 112px;
		}

		.button {
			margin-top: 70px;

			@include below($l) {
				margin-top: 50px;
			}
		}
	}
}

.ingress {
	text-align: center;
	max-width: 730px;
	margin: 0 auto 100px;

	@include below($m) {
		margin-bottom: 60px;
	}
}

.text {
	.article-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.wysiwyg {
		max-width: 598px;
	}
}

.cta {
	>div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	p {
		max-width: 598px;
		text-align: center;
	}
}

.info {
	background-color: $pink;

	.wysiwyg {
		max-width: 730px;
	}

	.blueprint-accordion {
		max-width: 730px;
	}

	.blueprint-accordion__table-columns.five-colums.blueprint-accordion__table-columns-titles li {
		width: calc(100% / 5);
    	max-width: calc(100% / 5);
	}

	.blueprint-accordion__table-columns.five-colums li {
		width: calc(100% / 5);
    	max-width: calc(100% / 5);
	}
}

.image_cards {
	.cards-wrapper {
		display: flex;
		column-gap: 27px;
		row-gap: 27px;

		@include below($m) {
			flex-direction: column;
		}
	}

	a {
		transition: opacity 0.2s;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	a:hover {
		opacity: 0.8;
	}

	h3 {
		border-top: 5px solid black;
	}
}