.area,
.area-boden {
	cursor: pointer;

	&:hover {
		opacity: 0.7;
	}
}

.number:hover {
	opacity: 1;

	.k {
		opacity: 0.5;
	}
}

.area-map,
.area-map-boden {
	display: flex;
	flex-direction: column;
}

.area-map-flex {
	display: flex;
	flex-direction: row;

	@include below($l) {
		flex-direction: column;
	}
}

.map-area-list {
	padding: 0 30px;
	margin-top: 30px;
	background-color: $primaryBlue;
}

.area-map-flex .map-area-list {
	padding: 0 30px;
	margin-top: 0px;
	margin-left: 30px;
	background-color: $primaryBlue;
	max-width: 350px;
	width: 100%;

	@include below($l) {
		margin-top: 30px;
		margin-left: 0px;
		max-width: 100%;
	}
}

.area-list-block {
	border-bottom: solid white 1px;

	&:last-of-type {
		border-bottom: none;
	}
}

.area-list-title {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.area-list-title a {
	padding: 25px 0;
	font-weight: 700;
	color: white;
	text-decoration: underline;
	width: 100%;
}


.area-list-number {
	background-color: black;
	border-radius: 100px;
	height: 30px;
	max-width: 30px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 20px;
}

.area-list-number span {
	color: white;
	font-weight: 700;
}

.maxWidth {
	max-width: 725px;
	width: 100%;

	@include below($l) {
		max-width: 100%;
	}
}