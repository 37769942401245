//Margins and paddings
.nmr {
	margin-right: 0 !important;
}

.mbSecondary {
	margin-bottom: 12px;
}

.mbPrimary {
	margin-bottom: 24px;
}

.mb18 {
	margin-bottom: 18px;
}

.mb4 {
	margin-bottom: 4px;
}

.mb30 {
	margin-bottom: 30px;
}

.mb60 {
	margin-bottom: 60px;
}

.mb80 {
	margin-bottom: 80px;
}

.mb90 {
	margin-bottom: 90px;
}

.mb120 {
	margin-bottom: 120px;
}

.mb150 {
	margin-bottom: 150px;
}

.mbDouble {
	margin-bottom: 48px;
}

.mt5 {
	margin-top: 6px;
}

.mtSecondary {
	margin-top: 12px;
}

.mt17 {
	margin-top: 17px;
}

.mtPrimary {
	margin-top: 24px;
}

.mt50 {
	margin-top: 50px;
}

.mt60 {
	margin-top: 60px;
}

.mt70 {
	margin-top: 70px;
}

.mt90 {
	margin-top: 90px;
}

.mt80 {
	margin-top: 80px;
}

.mt120 {
	margin-top: 120px;
}

.mt150 {
	margin-top: 150px;
}

.my150 {
	margin-top: 150px;
	margin-bottom: 150px;
}

.mr32 {
	margin-right: 32px;
}

.ml16 {
	margin-left: 16px;
}

.npt {
	padding-top: 0;
}

.py10 {
	padding-top: 10px;
	padding-bottom: 10px;
}

.py120 {
	padding-top: 120px;
	padding-bottom: 120px;
}

.pt10 {
	padding-top: 10px;
}

.npl {
	padding-left: 0;
}

.pt8 {
	padding-top: 8px;
}

.pt12 {
	padding-top: 12px;
}

.pb40 {
	padding-bottom: 40px;
}

.np {
	padding: 0;
}

.npb {
	padding-bottom: 0;
}

.m-half {
	margin: 12.5px 0;
}

.responsive_mb60 {
	margin-bottom: 60px;

	@include below($m) {
		margin-bottom: 30px;
	}
}

.responsive_mb90 {
	margin-bottom: 90px;

	@include below($m) {
		margin-bottom: 60px;
	}
}

.responsive_mb120 {
	margin-bottom: 120px;

	@include below($m) {
		margin-bottom: 80px;
	}
}

.responsive_mb150 {
	margin-bottom: 150px;

	@include below($m) {
		margin-bottom: 80px;
	}
}

.responsive_py120 {
	padding: 120px 0;

	@include below($m) {
		padding: 80px 0;
	}
}

//Rules
.db {
	display: block;
}

.ib {
	display: inline-block;
}

.right {
	float: right;
}

.of-x-hidden {
	overflow-x: hidden;
}

.of-y-hidden {
	overflow-y: hidden;
}

//Colors
.c-white {
	color: white;
}

.c-blue {
	color: $primaryBlue;
}

.c-gray {
	color: $lightGray;
}

.bg-blue {
	background-color: $primaryBlue;
}

.half-op {
	opacity: 0.5;
}

//Typo
.w-medium,
.w-medium p {
	font-weight: $medium !important;
}

.w-semibold,
.w-semibold p {
	font-weight: $semibold !important;
}

.ta-left {
	text-align: left !important;
}

.uppercase {
	text-transform: uppercase;
}

.underline {
	text-decoration: underline;
}

//devices
.hide-desktop {
	display: none;

	@include below($m) {
		display: block;
	}
}

.hide-mobile {
	display: block;

	@include below($m) {
		display: none;
	}
}

.h-100 {
	height: 100%;
}