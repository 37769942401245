//Base typography
body {
	font-family:'Graphik Web', 'Helvetica', 'Arial', sans-serif;
	font-size:16px;
	line-height:24px;

	@include below($m) {
		font-size:14px;
		line-height:24px;
	}
	@include below($s) {
		line-height:20px;
	}
}

//Copy
.copy {
	&.small-thin {
		font-size:14px;
		line-height:18px;
		font-weight:400;
	}
	&.small {
		font-size:14px;
		line-height:18px;
		font-weight:500;
	}
	&.smaller {
		font-size:12px;
		line-height:18px;
		font-weight:500;
	}
	&.large {
		font-size:18px;
		line-height:24px;
		@include below($s) {
			font-size:16px;
			line-height:20px;
		}
	}
	&.x-large,
	&.x-large p {
		font-size:24px;
		line-height:32px;
		font-weight: $semibold;
		&.regular {
			font-weight: $regular;
		}
		&.thin,
		&.thin p,
		&.thin a {
			font-weight: $thin;
		}
		@include below($s) {
			font-size:18px;
			line-height:24px;

			&.lh36 {
				line-height: 28px !important;
			}
		}
	}
	&.lh36 {
		line-height: 36px !important;
	}
}

//Here be titles (and dragons)
.title-xxsmall {
	font-size:14px;
	font-weight:$semibold;
	color:$black;
}
.title-xsmall {
	font-size:16px;
	font-weight:$semibold;
	color:$black;

	@include below($m) {
		font-size:16px;
		line-height:24px;
	}
}
.title-small, .article-content h4 {
	font-size:18px;
	font-weight:$semibold;
	color:$black;

	@include below($m) {
		font-size:18px;
		line-height:24px;
	}
}

.title-22 {
	font-size:22px;
	font-weight:$semibold;
}

.title-msmall {
	font-size:24px;
	font-weight:$semibold;
	color:$black;

	@include below($m) {
		font-size:18px;
		line-height:24px;
	}
}

.title-standard, .article-content h3 {
	font-size:24px;
	line-height:32px;
	font-weight:$semibold;
}

.title-medium, .article-content h2 {
	font-size:32px;
	line-height:40px;
	color:$black;
	font-weight:$semibold;

	@include below($m) {
		font-size:24px;
		line-height:32px;
	}
}

.title-large {
	font-size:58px;
	line-height:60px;
	font-weight:$semibold;
	color:$black;

	@include below($m) {
		font-size:24px;
		line-height:32px;
	}
}

.title-large-full {
	font-size:58px;
	line-height:60px;
	font-weight:$semibold;
	color:$black;
}

.navigation-subtitle {
	color:white;
	font-weight:$semibold;
	position: relative;
	padding-bottom:15px;
	margin-bottom:15px;
	display: block;
	&:after {
		content:'';
		position: absolute;
		bottom:0;
		left:0;
		width:100%;
		height:5px;
		background:white;
	}
	@include below($s) {
		padding:0;
		margin-bottom:0px;
		&:after {
			display: none;
		}
	}
}

.uppercase {
	text-transform: uppercase;
}

//HREFZ
.link {
	font-size:16px;
	line-height:24px;
	text-decoration: underline;
	color:$primaryBlue;
	font-weight:$medium;
	&:hover {
		opacity:0.5;
	}
	&.white {
		color:white;
		&:hover {
			opacity:0.5;
		}
	}
	&.black {
		color:$black;
	}
	&.small {
		font-size:14px;
		font-weight:$medium;
	}
	&.thin {
		font-weight:400;
	}
	&.active {
		text-decoration: none;
		opacity:0.5;
	}
}
.hover-underline {
	transition: .1s;

	&:hover {
		text-decoration: underline;
	}
}

.hover-opacity {
	transition: .1s;

	&:hover {
		opacity: 1;
	}
}
.blue-btn {
	cursor: pointer;
    display: inline-block;
    position: relative;
    background-color: #1482FA;
    width: 100%;
    border-radius: 0.25rem;
    text-align: left;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #fff;
	font-weight: $semibold;
	width: auto;
	transition: .2s;

	&:hover {
		opacity: .9;
	}
}

.gray-btn {
	cursor: pointer;
    display: inline-block;
    position: relative;
    background-color: #979797;
    width: 100%;
    border-radius: 0.25rem;
    text-align: left;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #fff;
	font-weight: $semibold;
	width: auto;
	transition: .2s;

	&:hover {
		opacity: .9;
	}
}

.white-btn {
	cursor: pointer;
    display: inline-block;
    position: relative;
    background-color: #fff;
    width: 100%;
    border-radius: 0.25rem;
    text-align: left;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #black;
	font-weight: $semibold;
	width: auto;
	transition: .2s;

	&:hover {
		opacity: .9;
	}
}
