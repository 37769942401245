.frontpage-press {
	position: relative;
	padding-top:24px;
	&:before {
		@include pre-line($black, 5px);
	}
	
}

.frontpage-press-list {
	margin:24px 0;
	display: block;
	@include below($s) {
		margin:24px 0 12px 0;
	}
}

.press-item {
	margin-bottom:24px;
	position: relative;
	min-height:160px;
	a {
		color:$black;
		&:hover .press-hover {
			text-decoration: underline;
		}
	}
	@include below($m) {
		min-height:auto;
		width:100%;
	}
}
.press-item-image {
	display: inline-block;
	float:left;
	// width:22.142%;
	width: 160px;
	@include vertical-align(absolute);
	@include below($m) {
		width: 130px;
	}
	@include below($s) {
		width: 90px;
	}
}
.press-item-caption {
	display: inline-block;
	// float:right;
	// width:75%;
	// padding-right:24px;
    width: 65%;
    // float: none;
    margin-left: 190px;
	@include below($m) {
	    width: calc(100% - 220px);
	    margin-left: 160px;
		.title-medium {
			margin-top:0;
		}
		p {
			margin-top:5px;
		}
	}
	@include below($s) {
		width: calc( 100% - 110px );
		margin-left: 110px;
		.press-hover {
			display: none;
		}
		.title-medium {
			font-size:18px;
			line-height:24px;
		}

	}
}